import React, { useEffect, useMemo } from "react";
import { AutocompleteState, BaseItem } from "@algolia/autocomplete-core";
import { ReactComponent as SearchAlternate } from "../../images/icons/search-alternate.svg";

import AutocompleteConfig from "src/components/autocomplete/autocomplete_config";

import debounce from "src/debounce";

export default function AutocompleteRenderer<T extends BaseItem>({
  autocompleteState,
  autocompleteConfig,
}: {
  autocompleteState: AutocompleteState<T>;
  autocompleteConfig: AutocompleteConfig<T>;
}) {
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const resultsRef = React.useRef<HTMLInputElement>(null)

  const [debounceLoading, cancelDebouncedLoading] = useMemo(() => debounce(setShowLoading, 200), []);

  const collectionFromId = React.useCallback((sourceId: string) => autocompleteState.collections.find(collection => collection.source.sourceId === sourceId), [autocompleteState.collections]);
  const emptyQueryCollection = React.useMemo(() => collectionFromId(autocompleteConfig.emptyQueryCollectionId), [collectionFromId, autocompleteConfig.emptyQueryCollectionId]);
  const queryCollection = React.useMemo(() => collectionFromId(autocompleteConfig.queryCollectionId), [collectionFromId, autocompleteConfig.queryCollectionId]);

  useEffect(() => {
    if (autocompleteState.status == "loading") {
      debounceLoading(true);
    }
    if (autocompleteState.status == "idle") {
      cancelDebouncedLoading();
      setShowLoading(false);
    }
  }, [autocompleteState.status, debounceLoading, cancelDebouncedLoading]);

  const renderEmptyQueryResults = React.useMemo(() => {
    return (
      <div className="list has-hoverable-items" ref={resultsRef}>
        <div className="space-pl-md space-pr-md space-pb-xs">
          <span className="label-text description">{autocompleteConfig.emptyQueryCollectionTitle}</span>
        </div>
        {emptyQueryCollection?.items.map((item, index) => autocompleteConfig.itemComponent(item, index))}
      </div>
    );
  }, [emptyQueryCollection?.items, autocompleteConfig])

  const renderSearchResults = React.useMemo(() => {
    return (
      <div className="list has-hoverable-items">
        {queryCollection?.items.map((item, index) => autocompleteConfig.itemComponent(item, index))}
        {autocompleteConfig.searchResultsPath(autocompleteState.query) ?
          <a className="list-item" href={autocompleteConfig.searchResultsPath(autocompleteState.query)} >
            <div className="is-flexbox space-p-xs">
              <SearchAlternate className="icon streamline-icon is-medium" />
            </div>

            <span className="label-text">All results for <b>{autocompleteState.query}</b></span>
          </a> : <></>
        }

      </div>
    );
  }, [autocompleteState.query, queryCollection?.items, autocompleteConfig]);

  const noResults = React.useMemo(() => {
    return (
      <div className="list">
        <span className="list-item label-text">No results</span>
      </div>
    )
  }, [])

  const topSearchesResults = useMemo(() => {
    return (
      <section className="space-pt-none space-pb-none">
        <div className="space-pl-md space-pr-md space-pb-xs">
          <span className="label-text description">Popular searches</span>
        </div>

        <div className="is-flexbox flex-wrap space-pl-md space-pt-sm">
          {autocompleteConfig.topSearchResult()}
        </div>
      </section>
    )
  }, [autocompleteConfig])

  const renderResultsLoadingPlaceholder = (
    <div className="container">
      <div className="skeleton is-heading"></div>
      <div className="skeleton is-paragraph"></div>
      <div></div>
    </div>
  )

  const autocompleteResults = React.useCallback(() => {
    if (autocompleteConfig.topSearchResult().length > 2 && autocompleteState.query.length == 0) {
      return topSearchesResults;
    } else if (emptyQueryCollection && emptyQueryCollection.items.length == 0) {
      return <></>;
    } else if (emptyQueryCollection && emptyQueryCollection.items.length > 0) {
      return renderEmptyQueryResults;
    } else if (queryCollection?.items?.length) {
      return renderSearchResults;
    } else {
      return noResults;
    }
  }, [noResults, emptyQueryCollection, renderEmptyQueryResults, renderSearchResults, queryCollection, topSearchesResults, autocompleteState, autocompleteConfig]);

  return showLoading ? renderResultsLoadingPlaceholder : autocompleteResults();
}
